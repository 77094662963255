import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

import Img from "gatsby-image"

import { makeStyles } from '@material-ui/core/styles'
import { createGlobalStyle } from "styled-components"

import Container from '@material-ui/core/Container'
import Typography from '@material-ui/core/Typography'
import LockIcon from '@material-ui/icons/Lock'

const PrivacyPage = ({ data, location }) => {
  const classes = useStyles()

  return (
    <Layout location={location} title="Work">
      <GlobalStyle />
      <SEO title={`Privacy & pollicy & Terms`}/>
      <Container maxWidth="md" >
        <div className={classes.root}>
          <h1 className={classes.title}>
            <LockIcon className={classes.icon}/><span className={classes.about}>Privacy & Poclicy</span>
          </h1>
          <Img
            fluid={data.coverImage.childImageSharp.fluid}
            className={classes.coverImg}
            objectFit="cover"
            alt=""
            />
          <div id="privacy">
            <Typography variant="body1" component="p">
              株式会社UKAI（以下，「当社」といいます。）は，本ウェブサイト上で提供するサービス（以下、「本サービス」といいます。）におけるプライバシー情報の取扱いについて，以下のとおりプライバシーポリシー（以下，「本ポリシー」といいます。）を定めます。
            </Typography>
            <Typography variant="h6" component="h2">第１条 プライバシー情報 </Typography>
            <Typography variant="body1" component="p">
              プライバシー情報のうち「個人情報」とは，個人情報保護法にいう「個人情報」を指すものとし，生存する個人に関する情報であって，当該情報に含まれる氏名，電話番号，連絡先その他の記述等により特定の個人を識別できる情報を指します。
              <br />
              プライバシー情報のうち「履歴情報および特性情報」とは，上記に定める「個人情報」以外のものをいい、ご利用日時，ご利用の方法，ご利用環境，ユーザーのIPアドレス，クッキー情報，端末の個体識別情報などを指します。
            </Typography>
            <Typography variant="h6" component="h2">第２条 プライバシー情報の収集方法</Typography>
            <Typography variant="body1" component="p">
              当社は、ユーザーがお問い合わせの際に名前、メールアドレス、お電話番号をご入力いただきます。
              <br />
              当社は，ユーザーについて利用日時，利用方法，利用環境（携帯端末を通じてご利用の場合の当該端末の通信状態，利用に際しての各種設定情報なども含みます），IPアドレス，クッキー情報，位置情報，端末の個体識別情報などの履歴情報および特性情報を，ページを閲覧する際に収集することがございます。
            </Typography>
            <Typography variant="h6" component="h2">第３条　個人情報を収集・利用する目的</Typography>
            <Typography variant="body1" component="p">
              ユーザーからのお問い合わせに対応するために，お問い合わせ内容など当社がユーザーに対してサービスを提供するにあたって必要となる情報や，ユーザーのサービス利用状況，連絡先情報などを利用する目的
              <br />
              上記の利用目的に付随する目的
            </Typography>
            <Typography variant="h6" component="h2">第４条 個人情報の第三者への開示・提供の禁止</Typography>
            <Typography variant="body1" component="p">
              当社は、お客さまよりお預かりした個人情報を適切に管理し、次のいずれかに該当する場合を除き、個人情報を第三者に開示いたしません。
              <br />
              - ユーザーの同意がある場合
              <br />
              - ユーザーが希望されるサービスを行なうために当社が業務を委託する業者に対して開示する場合
              <br />
              - 法令に基づき開示することが必要である場合
            </Typography>
            <Typography variant="h6" component="h2">第５条 個人情報の安全対策</Typography>
            <Typography variant="body1" component="p">
              当社は、個人情報の正確性及び安全性確保のために、セキュリティに万全の対策を講じています。
            </Typography>
            <Typography variant="h6" component="h2">第６条 ご本人の照会</Typography>
            <Typography variant="body1" component="p">
              お客さまがご本人の個人情報の照会・修正・削除などをご希望される場合には、ご本人であることを確認の上、対応させていただきます。
            </Typography>
            <Typography variant="h6" component="h2">第７条 法令、規範の遵守と見直し</Typography>
            <Typography variant="body1" component="p">
              当社は、保有する個人情報に関して適用される日本の法令、その他規範を遵守するとともに、本ポリシーの内容を適宜見直し、その改善に努めます。
            </Typography>
            <Typography variant="h6" component="h2">第８条 お問い合せ</Typography>
            <Typography variant="body1" component="p">
              当社の個人情報の取扱に関するお問い合せは下記までご連絡ください。
              <br />
              株式会社UKAI<br />〒415-0012 静岡県下田市白浜1692 <br />TEL: 0558-23-0337
            </Typography>
          </div>

          <h1 className={classes.title}>
            <LockIcon className={classes.icon}/><span className={classes.about}>Terms</span>
          </h1>
          <div id="terms">
            <Typography variant="body1" component="p">
              この利用規約（以下，「本規約」といいます。）は，株式会社UKAI（以下，「当社」といいます。）がこのウェブサイト上で提供するサービス（以下，「本サービス」といいます。）の利用条件を定めるものです。登録ユーザーの皆さま（以下，「ユーザー」といいます。）には，本規約に従って，本サービスをご利用いただきます。            </Typography>
            <Typography variant="h6" component="h2">第１条 適用 </Typography>
            <Typography variant="body1" component="p">
              本規約は，ユーザーと当社との間の本サービスの利用に関わる一切の関係に適用されるものとします。
            </Typography>
            <Typography variant="h6" component="h2">第2条（禁止事項）</Typography>
            <Typography variant="body1" component="p">
              ユーザーは，本サービスの利用にあたり，以下の行為をしてはなりません。
              - 法令または公序良俗に違反する行為<br />
              - 犯罪行為に関連する行為<br />
              - 当社のサーバーまたはネットワークの機能を破壊したり，妨害したりする行為<br />
              - 当社のサービスの運営を妨害するおそれのある行為<br />
              - 他のユーザーに関する個人情報等を収集または蓄積する行為<br />
              - 他のユーザーに成りすます行為<br />
              - 当社のサービスに関連して，反社会的勢力に対して直接または間接に利益を供与する行為<br />
              - その他，当社が不適切と判断する行為
            </Typography>
            <Typography variant="h6" component="h2">第3条（本サービスの提供の停止等）</Typography>
            <Typography variant="body1" component="p">
              当社は，以下のいずれかの事由があると判断した場合，ユーザーに事前に通知することなく本サービスの全部または一部の提供を停止または中断することができるものとします。<br />
              - 本サービスにかかるコンピュータシステムの保守点検または更新を行う場合<br />
              - 地震，落雷，火災，停電または天災などの不可抗力により，本サービスの提供が困難となった場合<br />
              - コンピュータまたは通信回線等が事故により停止した場合<br />
              - その他，当社が本サービスの提供が困難と判断した場合<br />
              - 当社は，本サービスの提供の停止または中断/記事の内容について，ユーザーまたは第三者が被ったいかなる不利益または損害について，理由を問わず一切の責任を負わないものとします。<br />
            </Typography>
            <Typography variant="h6" component="h2">第4条（サービス内容の変更等）</Typography>
            <Typography variant="body1" component="p">
              当社は，ユーザーに通知することなく，本サービスの内容を変更しまたは本サービスの提供を中止することができるものとし，これによってユーザーに生じた損害について一切の責任を負いません。
            </Typography>
            <Typography variant="h6" component="h2">第5条（利用規約の変更）</Typography>
            <Typography variant="body1" component="p">
              当社は，必要と判断した場合には，ユーザーに通知することなくいつでも本規約を変更することができるものとします。
            </Typography>
            <Typography variant="h6" component="h2">第6条（通知または連絡）</Typography>
            <Typography variant="body1" component="p">
              ユーザーと当社との間の通知または連絡は，当社の定める方法によって行うものとします。
            </Typography>
            <Typography variant="h6" component="h2">第7条（権利義務の譲渡の禁止）</Typography>
            <Typography variant="body1" component="p">
              ユーザーは，当社の書面による事前の承諾なく，利用契約上の地位または本規約に基づく権利もしくは義務を第三者に譲渡し，または担保に供することはできません。
            </Typography>
            <Typography variant="h6" component="h2">第8条（準拠法・裁判管轄）</Typography>
            <Typography variant="body1" component="p">
              - 本規約の解釈にあたっては，日本法を準拠法とします。<br />
              - 本サービスに関して紛争が生じた場合には，当社の本店所在地を管轄する裁判所を専属的合意管轄とします。<br />
            </Typography>
          </div>
        </div>
      </Container>
    </Layout>
  )
}

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
        description
      }
    }
    coverImage: file(absolutePath: { regex: "/privacy.jpg/" }) {
      childImageSharp {
        fluid(maxWidth: 1000, quality: 90) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
  }
`
const GlobalStyle = createGlobalStyle`
  #privacy, #terms {
    h1,h2,h3,h4,h5,h6 {
      border-left: 0.42813rem solid #00b0ff;
      padding-left: 1.42188rem;
      color: #00b0ff;
      margin-bottom: 20px;
      margin-top: 20px;
    }
    a {
      box-shadow: 0 1px 0 0 currentColor;
      color: #007acc;
      text-decoration: none;
    }
    mark, ins {
      background: #00b0ff
    }
  }
`

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    marginBottom: theme.spacing(5),
    marginTop: theme.spacing(10),
  },
  title: {
    textAlign: `center`,
  },
  coverImg: {
    height: `430px`,
    marginBottom: theme.spacing(5),
  },
  about: {
    verticalAlign: `top`
  },
  link: {
    boxShadow: "none",
    textDecoration: `none`,
  },
  icon: {
    fontSize: 40,
  },

}))

export default PrivacyPage
